@import "../Styles/variable.scss";
.about_img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    justify-self: center;
    justify-content: center;
    flex-direction: column;
    // animation: profile_animate 8s ease-in-out infinite 1s;
}

.about_info {
    gap: 0.75rem;
}

.about_box {
    border: 1px solid gray;
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about_icon {
    font-size: 1.5rem;
    color: $title-color;
}

.about_title {
    font-size: $normal-font-size;
    font-weight: $font-medium;
}

.about_subtitle {
    font-size: $body-font;
}

img.social-icon {
    width: 3%;
}