@import "../Styles/variable.scss";
//Google Font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
//Fonts & Typography
// --body-font: "Poppins" , sans-serif;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    overflow: overlay;
}

.floatingNav {
    width: calc(100vh - 100px);
    border-radius: 2px;
    box-shadow: 0px 1px 10px #999;
}

.container {
    width: 80% !important;
    margin: auto;
}

.align-item-center {
    align-items: center;
}

html {
    scroll-behavior: smooth;
}

body,
button,
input,
textarea {
    font-family: $body-font;
    font-size: $normal-font-size;
}

h1,
h2,
h3 {
    font-weight: $font-semi-bold;
}

h1 {
    font-size: $h1-font-size !important;
}

h2 {
    font-size: $h2-font-size !important;
}

h3 {
    font-size: $h3-font-size !important;
}

h4 {
    font-size: $h4-font-size !important;
}

p {
    font-size: 15px;
    margin-bottom: 0 !important;
}

ul {
    list-style: none;
    padding: 0 !important;
    margin: 0;
}

.title-color {
    color: $title-color;
}

dl,
ol,
ul {
    margin-bottom: 0 !important;
}

a {
    text-decoration: none !important;
}

.margin-auto {
    margin: auto;
}

// .section_title::after {
//     bottom: 0px;
//     left: 50%;
//     margin-left: -35px;
//     width: 70px;
//     background-color: #d810dc;
//     content: '';
//     height: 1px;
//     position: absolute;
// }
button {
    cursor: pointer;
    border: none;
    outline: none;
}

.btn.btn-primary {
    border-radius: 50px;
    padding: 7px 20px;
}

img {
    max-width: 100%;
    height: auto;
}

.w-9 {
    width: 9%;
}

.bg-color {
    background-color: $bg-color;
}

.bg-lgt {
    background-color: $bg-lgt;
}

.text-lgt {
    color: $text-lgt;
}

img.logo-home {
    width: 35%;
}

//Resuable class
.section {
    padding: 6rem 0 6rem;
}

.sec {
    padding: 6rem 0;
}

.section-title {
    font-size: $h1-font-size;
    color: green;
}

.section_subtitle {
    display: block;
    font-size: $small-font-size;
    margin-bottom: 4rem;
}

span.services_button {
    color: $hover;
    cursor: pointer;
}

span.services_button:hover {
    color: $btn-hover-bg;
}

//nav::begin
.header {
    width: 100%;
    // position: fixed;
    top: 0;
    left: 0;
    z-index: $z-fized;
    background-color: $container-color;
}

header.header.active.fixed-top {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
}

.nav {
    //display: flex;
    height: $header-height;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav_logo,
.nav_toggle {
    color: $title-color;
    font-weight: $font-medium;
}

.nav_list {
    display: flex;
    column-gap: 2rem;
}

.nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .3s;
    color: $text-lgt;
}

.nav_close,
.nav_toggle {
    display: none;
}

//Active link
.active-link:hover {
    color: $hover;
}

// expertise tab
.menu-tab {
    gap: 6px;
}

.filter {
    border: 1px solid #cccccc;
    cursor: pointer;
    display: block;
    padding: 8px 25px;
    background: transparent;
}

.filter.active {
    border: 1px solid #247aff;
    background: #247aff;
    color: #ffffff;
}

filter {
    border: 1px solid #cccccc;
    cursor: pointer;
    display: block;
    padding: 8px 25px;
    background: transparent;
    text-transform: capitalize;
}

filter::first-letter {
    text-transform: uppercase;
}

filter.active {
    border: 1px solid #247aff;
    background: #247aff;
    color: #ffffff;
}

.col-img {
    display: flex;
}

// contact icon
i.bx.bx-icon {
    border: 2px solid #247aff;
    color: #247aff;
    font-size: 28px;
    height: 56px;
    line-height: 52px;
    margin-bottom: 15px;
    width: 56px;
    border-radius: 50%;
}

i.bx.bx-icon:hover {
    // border: 2px solid #247aff;
    color: #ffffff;
    background-color: #247aff;
    transition: 0ms;
}

// Page Transation
.page-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #fff;
}

// .contact-section {
//     // background-image: url(ogo/map-bg.png);
//     background-image: url('../assets/map-bg.png');
//     background-size: cover;
// }
// span.filter:active {
//     border: 1px solid #247aff;
//     background: #247aff;
// }
//nav::end
//BreakPoint
//For larger devices
.modal-content {
    padding: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.navbar-light .navbar-nav .nav-link {
    // color: #424242;
    font-weight: 500;
}

.navbar-light .navbar-nav .nav-link {
    // color: #424242;
    font-weight: 500;
}

.w-80 {
    width: 85%;
}

// .navbar-light .navbar-nav .nav-link.active,
// .navbar-light .navbar-nav .show>.nav-link {
//     color: #0d6efd;
// }
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .home_img {
        width: 240px !important;
        height: 180px !important;
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 767.98px) {
    .home_content {
        text-align: center;
        justify-content: center;
    }
    .bnr-mob {
        justify-content: center;
        display: flex;
    }
    .w-75 {
        width: 100%!important;
    }
    img.social-icon {
        width: 5% !important;
    }
    .services_content {
        margin-bottom: 30px;
    }
    .filter-buttons.menu-tab.tabs {
        display: inline !important;
    }
    .Typewriter {
        font-size: 32px;
    }
}

@media screen and (max-width: 992px) {
    .container {
        margin-left: 20px;
        margin-right: 20px;
    }
}

//For medium devices
@media screen and (max-width: 768px) {
    // .container {
    //     padding-left: 20px !important;
    //     padding-right: 20px !important;
    // }
    .container {
        width: 90% !important;
        margin: auto;
    }
    .header {
        top: initial;
        bottom: 0;
    }
    .nav {
        height: $header-height;
    }
    .nav_menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: $container-color;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
    //show Menu
    .show-menu {
        bottom: 0;
    }
    .nav_close {
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: $title-color;
    }
    .nav_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        padding-left: 0;
    }
    .nav_toggle {
        cursor: pointer;
    }
    .nav_close,
    .nav_toggle {
        display: block;
    }
}

//For small device
@media screen and (max-width: 350px) {
    //nav
    .nav_menu {
        padding: 2rem 0.25rem 4rem;
    }
    .nav_list {
        column-gap: 0;
    }
}