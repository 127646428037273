$container-color: #ffffff;
$bg-color: #460b89;
$bg-lgt: #f6f8ff;
$title-color: #333333;
$text-lgt: #666666;
// $text-lgt: #767683;
$hover: #247aff;
$btn-hover-bg: #0b5ed7;
$body-font: 'Poppins',
sans-serif;
$header-height: 6rem;
$big-font-size: 3.5rem;
$h1-font-size: 3.5rem;
$h2-font-size: 3rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.10rem;
$normal-font-size: 1rem;
$small-font-size: 0.875rem;
$smaller-font-size: 0.813rem;
$tiny-font-size: 0.625;
//font weight
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
//z index
$z-tooltip: 10;
$z-fized: 100;
$z-modal: 1000;