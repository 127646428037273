@import "../Styles/variable.scss";
.home_content {
    align-items: center;
}

.home_social {
    display: grid;
    row-gap: 1rem;
}

.home_social-icon {
    font-size: 1.25rem;
    color: $title-color;
}

.home_social-icon:hover {
    color: $hover;
}

.home_title {
    font-size: $big-font-size;
}

.home-description {
    max-width: 600px;
}

.home_img {
    // background-image: url(../assets//profile.jpg);
    background-image: url(../assets//devSecops.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset;
    // box-shadow: inset 0 0 0 9px gray 0.5;
    order: 1;
    justify-self: center;
    width: 400px;
    height: 300px;
    animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}