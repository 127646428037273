@import "../Styles/variable.scss";
.services_modal {
    display: none;
}

// .services_content {
//     position: relative;
//     border: 1px solid gray;
//     background-color: #ffffff;
//     padding: 6rem 0 2rem 2.5rem;
// }
.services_icon {
    display: block;
    font-size: 2rem;
    color: $title-color;
}